import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    //Swipeable Views
    image: {
        width: '100%',
        minWidth: '280px',
        height: '500px',
        objectFit: 'cover',
    },
    slideViews: {
        display: 'none !important',
    },
    infoContainer: {
        padding: '12px 24px',
        [theme.breakpoints.down('xs')]: {},
    },
    imageContainer: {
        height: '500px !important',
        width: '50vw',
        [theme.breakpoints.down('xs')]: {
            height: '500px !important',
            width: '100vw !important',
            minWidth: '320px !important',
        },
    },
    iframe: {
        background: 'transparent',
        width: '100%',
        minHeight: '500px',
    },
    deleteSrc: {
        position: 'absolute',
        marginLeft: '100%',
        top: '2px',
        width: '75px',
        height: '75px',
        borderRadius: '75px',
        background: 'transparent',
        fontSize: '18px',
        fontWeight: 900,
        color: 'red',
        top: '2px',
        right: '2px',
    },
    // Buttons on SwipeableViews
    fileAddButton: {
        width: '100%',
        height: '100%',
        maxWidth: '48px !important',
        maxHeight: '48px !important',
        margin: '0 auto !important',
        padding: '0px !important',
        background: 'rgba(1,1,1,0.5)',
        borderRadius: '50px',
    },
    addImageButton: {
        width: '100%',
        height: '100%',
        maxWidth: '48px !important',
        maxHeight: '48px !important',
        margin: '0 auto !important',
        padding: '0px !important',
        borderRadius: '50px',
    },
    modalTrigger: {
        // [theme.breakpoints.down('xs')]: {
        //   margin: '0 0%'
        // },
        position: 'relative',
        top: '-500px',
        zIndex: 100,
        margin: '0 auto',
        width: '64px',
        height: '500px',
        padding: '218px 0px',
    },
    text: {
        marginBottom: theme.spacing(3),
    },
    price: {
        fontWeight: 600,
        fontSize: '1.5rem',
    },
    buttonsOverlay: {
        position: 'relative',
        width: '100% !impotant',
    },
    imageButton: {
        position: 'absolute',
        zIndex: 150,
        top: 500 / 2 - 59 / 2,
        color: theme.palette.colors.white,
        background:
            'radial-gradient(circle, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.1) 50%, rgba(0,0,0,0) 75%)',
    },
    imageLeftButton: {
        left: 25,
    },
    imageRightButton: {
        right: 25,
    },
    // Experience Container
    experienceTitle: {
        '&::placeholder': {
            color: '#AFB0B9',
        },
        fontSize: '44px',
        color: '#4F4F4F',
        lineHeight: '1',
        fontWeight: '700',
        fontFamily: 'DM Sans',
        marginLeft: '-3px',
        marginTop: '6px',
        padding: '0px !important',
        borderRadius: '0px !important',
        [theme.breakpoints.down('xs')]: {
            fontSize: '24px',
        },
    },
    experienceSubTitle: {
        '&::placeholder': {
            color: '#AFB0B9',
        },
        marginTop: '20px',
        marginBottom: '7.5px',
        color: '#4F4F4F',
        fontSize: '22px',
        fontWeight: '500',
        fontFamily: 'DM Sans',
        borderRadius: '0px !important',
        [theme.breakpoints.down('xs')]: {
            fontSize: '20px',
            marginTop: '-5px',
            lineHeight: '20px',
        },
    },
    experienceDescription: {
        '&::placeholder': {
            color: '#AFB0B9',
        },
        marginBottom: '10px',
        color: '#4F4F4F',
        fontSize: '18px',
        fontWeight: '500',
        fontFamily: 'DM Sans',
        borderRadius: '0px !important',
        border: 'none',
        overflowX: 'none',
        overflowY: 'auto',
        outline: 'none',
        resize: 'none',
        width: '100%',
        height: '12rem',
    },
    conteinerGrid: {
        // width: '100%',
    },
    conteinerWrapper: {
        // [theme.breakpoints.down('xs')]: {
        //     width: '147px !important',
        //     maxWidth: '147px !important',
        //     margin: '0 auto',
        //     padding: '0px',
        // },
    },
    locationIcon: {
        width: 50,
        [theme.breakpoints.down('xs')]: {
            marginLeft: 75,
        },
    },
    subExperienceRow: {
        height: '20px',
        padding: '0px',
        margin: '0px',
    },
    subExperience: {
        height: '20px',
        padding: '0px !important',
        margin: '0px',
        lineHeight: '20px !important',
    },
    subExperienceField: {
        height: '20px',
        padding: '0px !important',
        margin: '0px',
        lineHeight: '20px !important',
    },
    addIcon: {
        lineHeight: '20px !important',
        weight: '20px',
        height: '20px',
    },
    submitButtonWrapper: {
        // width: '100%',
    },
    submitButton: {
        // float: 'right',
        background: '#2F80ED',
        border: 'none !important',
        color: 'white',
        fontFamily: 'DM Sans',
        fontWeight: '700',
        width: '147px',
        // paddingTop: '9px',
        fontSize: '20px',
        borderRadius: '0px',
        marginRight: '42.5px',
        marginBottom: '24px',
        [theme.breakpoints.down('xs')]: {
            // float: 'none',
            width: '147px !important',
            maxWidth: '147px !important',
            margin: '16px auto',
            marginRight: '16px',
            marginBottom: '32px',
        },
    },
    notCover: {
        // float: 'right',
        background: 'rgba(0,0,0,.75)',
        border: 'none !important',
        color: 'white',
        fontFamily: 'DM Sans',
        fontWeight: '700',
        width: 'fitContent',
        bottom: '20px',
        marginLeft: '1.5rem',
        position: 'absolute',
        // paddingTop: '9px',
        fontSize: '16px',
        borderRadius: '20px',
        cursor: 'pointer',
        [theme.breakpoints.down('xs')]: {
            // float: 'none',
            width: '147px !important',
            maxWidth: '147px !important',
            margin: '16px auto',
            marginRight: '16px',
            marginBottom: '32px',
        },
        '&:hover': {
            background: 'rgba(50,50,50,.80)',
        },
        '&:hover:active': {
            background: 'rgba(0,0,0,1)',
        },
    },
    setCover: {
        // float: 'right',
        background: '#2F80ED',
        border: 'none !important',
        color: 'white',
        fontFamily: 'DM Sans',
        fontWeight: '700',
        width: 'fitContent',
        bottom: '20px',
        marginLeft: '1.5rem',
        position: 'absolute',
        // paddingTop: '9px',
        fontSize: '16px',
        borderRadius: '20px',
        cursor: 'default',
        [theme.breakpoints.down('xs')]: {
            // float: 'none',
            width: '147px !important',
            maxWidth: '147px !important',
            margin: '16px auto',
            marginRight: '16px',
            marginBottom: '32px',
        },
        '&:hover': {
            background: '#2F80ED',
        },
    },
    saveModal: {
        padding: '14px',
        zIndex: 300,
        [theme.breakpoints.down('sm')]: {
            padding: '8px',
        },
    },
    // Slide Modal CSS
    slideModal: {
        zIndex: 300,
        padding: '14px',
        width: '400px',
        [theme.breakpoints.down('sm')]: {
            padding: '8px',
        },
    },
    smTitle: {
        fontFamily: 'DM Sans',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '20px',
        lineHeight: '23px',
        //display: 'flex',
        alignItems: 'center',
    },
    smDivider: {
        width: '100%',
        marginTop: '9px',
        borderBottom: 'solid 0.5px #4F4F4F',
        background: 'transparent',
        textAlign: 'center',
        opacity: 0.75,
    },
    smMessage: {
        width: '70%',
        textAlign: 'center',
        paddingTop: '36px',
        paddingBottom: '36px',
        margin: '0 auto',
        fontFamily: 'DM Sans',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '18px',
        color: '#828282',
    },
    smFooter: {
        height: '36px',
        margin: '0 auto',
    },
    smCancelButton: {
        float: 'right',
        paddingTop: '9px',
        background: '#C4C4C4',
        border: 'none !important',
        borderRadius: '6px',
        color: 'white',
        fontFamily: 'DM Sans',
        fontWeight: '700',
        width: '99px',
        lineHeight: '1.75',
    },
    smButton: {
        paddingTop: '9px',
        marginLeft: '18px',
        background: '#2F80ED',
        border: 'none !important',
        borderRadius: '6px',
        color: 'white',
        fontFamily: 'DM Sans',
        fontWeight: '700',
        width: '100px',
        lineHeight: '1.75',
    },
    smCard: {
        maxWidth: '320px',
        margin: '10px 10px',
        display: 'inline-block',
    },
    smCardMedia: {
        transition: '3s',
        height: '110px',
    },
    smVideoFooter: {
        height: '50px',
        float: 'right',
    },
    smVideoCancelButton: {
        paddingTop: '9px',
        background: '#2F80ED',
        marginLeft: '14px',
        border: 'none !important',
        borderRadius: '6px',
        color: 'white',
        fontFamily: 'DM Sans',
        fontWeight: '700',
        width: '99px',
        lineHeight: '1.75',
    },
    smVideoSaveButton: {
        paddingTop: '9px',
        background: '#C4C4C4',
        border: 'none !important',
        borderRadius: '6px',
        color: 'white',
        fontFamily: 'DM Sans',
        fontWeight: '700',
        width: '99px',
        lineHeight: '1.75',
    },
}));
