const modalDataParserToObject = (input, modal) => {
    let output;
    switch (modal) {
        case 'envPolicies':
            output = {
                trashRecycling: false,
                greenEnergy: false,
                foodWasteOpt: false,
                ngoCollaboration: false,
                otherDescription: '',
            };
            if (input.length) {
                input.map((elem) => {
                    switch (elem.id) {
                        case 1:
                            output.trashRecycling = true;
                            break;
                        case 2:
                            output.greenEnergy = true;
                            break;
                        case 3:
                            output.foodWasteOpt = true;
                            break;
                        case 4:
                            output.ngoCollaboration = true;
                            break;
                        case 5:
                            output.otherDescription = elem.description;
                    }
                });
            }
            break;
        case 'cancelPolicies':
            output = {
                flexible: false,
                threeDays: false,
                oneDay: false,
                nonRefundable: false,
            };
            if (input.length) {
                input.map((elem) => {
                    switch (elem.id) {
                        case 1:
                            output.flexible = true;
                            break;
                        case 2:
                            output.threeDays = true;
                            break;
                        case 3:
                            output.oneDay = true;
                            break;
                        case 4:
                            output.nonRefundable = true;
                            break;
                    }
                });
            }
            break;
        case 'include':
            output = {
                transportation: false,
                ticket: false,
                food: false,
                beverage: false,
                photography: false,
                accommodation: false,
                otherDescription: '',
            };
            if (input.length) {
                input.map((elem) => {
                    switch (elem.id) {
                        case 1:
                            output.transportation = true;
                            break;
                        case 2:
                            output.ticket = true;
                            break;
                        case 3:
                            output.food = true;
                            break;
                        case 4:
                            output.beverage = true;
                            break;
                        case 5:
                            output.photography = true;
                            break;
                        case 6:
                            output.accommodation = true;
                            break;
                        case 7:
                            output.otherDescription = elem.description;
                            break;
                    }
                });
            }
            break;
        case 'require':
            output = {
                requireId: false,
            };
            if (input) {
                output.requireId = true;
            }
            break;
        case 'moduses':
            output = [];
            // FIXME: must be something of public knowledge, not something hardcoded here
            input
                .filter((modusId) => modusId != 4 && modusId != 6)
                .map((elem) => {
                    output.push({ id: elem });
                });
            break;
    }
    return output;
};

const modalDataParserToModel = (input, modus) => {
    const output = [];
    if (modus) {
        input.map((elem) => {
            output.push({ id: elem.id });
        });
    } else {
        Object.keys(input).map((key, index) => {
            if (input[key]) {
                if (index + 1 === Object.keys(input).length) {
                    output.push({
                        id: index + 1,
                        description: input['otherDescription'],
                    });
                } else {
                    output.push({ id: index + 1 });
                }
            }
        });
    }
    return output;
};

const modalCancelPoliciesParserToModel = (cancelPolicies) => {
    const output = {
        nonRefundable: cancelPolicies.nonRefundable,
        additionals: [],
    };
    cancelPolicies.additionals.forEach((additional) => {
        output.additionals.push({
            id: additional.id,
            percentage: additional.percentage,
            time: additional.time,
        });
    });
    return output;
};

const mediaDataParser = (input) => {
    let output = [];
    input.map((obj) => {
        if (obj.src.split('static').length > 1) {
            output.push({ image: obj.src, id: obj.id });
        } else {
            output.push({ videoUrl: obj.src, id: obj.id });
        }
    });
    return output;
};

const parsePaymentMethodsToModel = (paymentMethods) => {
    const acceptedPaymentMethods = [];

    paymentMethods.forEach((paymentMethod) => {
        acceptedPaymentMethods.push({
            id: paymentMethod.id,
        });
    });

    return acceptedPaymentMethods;
};

export const parseExperienceToModel = (experience, expId) => {
    return {
        id: expId,
        //experiencerId: experience.experiencerId,
        status: experience.status,
        name: experience.name,
        nativeName: experience.nativeName,
        description1: experience.description1,
        description2: experience.description2,
        description3: experience.description3,
        // expDetail: experience.expDetail,
        tags: experience.tags,
        website: experience.website,
        facebook: experience.facebook,
        instagram: experience.instagram,
        twitter: experience.twitter,
        email: experience.email,
        emailAlt: experience.emailAlt,
        phone: experience.phone,
        mobile: experience.mobile,
        whatsapp: experience.whatsapp,
        envPolicies: modalDataParserToModel(experience.envPolicies),
        cancelPolicies: modalCancelPoliciesParserToModel(
            experience.cancelPolicies
        ),
        include: modalDataParserToModel(experience.include),
        modus: modalDataParserToModel(experience.modus, 'modus'),
        location: experience.location,
        latitude: experience.latitude,
        longitude: experience.longitude,
        anticipation: experience.anticipation ?? 0,
        payNow: experience.payNowValue ?? 0,
        payNowType: experience.payNowType || 0,
        paymentMethods: parsePaymentMethodsToModel(experience.paymentMethods),
        pictureURL: experience.pictureURL,
        requireId: experience.requires.requireId ?? 0,
        currency: 'COP',
        price: experience?.price,
        duration: experience?.duration,
    };
};

export const parseExperienceToObject = (experience) => {
    return {
        id: experience.id,
        name: experience.name,
        nativeName: experience.nativeName,
        experiencerId: experience.experiencerId,
        status: experience.status,
        description1: experience.description1,
        description2: experience.description2,
        description3: experience.description3,
        tags: experience.tags,
        expDetail: experience.expDetail,
        website: experience.website,
        facebook: experience.facebook,
        instagram: experience.instagram,
        twitter: experience.twitter,
        email: experience.email,
        emailAlt: experience.emailAlt,
        phone: experience.phone,
        mobile: experience.mobile,
        whatsapp: experience.whatsapp,
        media: mediaDataParser(experience.media),
        envPolicies: modalDataParserToObject(
            experience.envPolicies,
            'envPolicies'
        ),
        cancelPolicies: experience.cancelPolicies,
        include: modalDataParserToObject(experience.include, 'include'),
        modus: modalDataParserToObject(experience.moduses, 'moduses'),
        location: experience.location || '',
        anticipation: experience.anticipation ?? 0,
        payNowValue: experience.payNow ?? 10,
        payNowType: experience.payNowType || 0,
        paymentMethods: experience.paymentMethods ?? [],
        pictureURL: experience.pictureURL,
        // parsePaymentMethodsToObject(experience.paymentMethods),
        requires: modalDataParserToObject(experience.requireId, 'require'),
        currency: 'COP',
        price: experience?.price,
        duration: experience?.duration,
    };
};
