import { useTranslation } from 'core/contexts/localization';
import GenericBox from '../GenericBox';

export const CheckEmailBox = ({ email }) => {
  const { t: translate } = useTranslation();

  return (
    <GenericBox
      title={translate('reset_password.check_email.title')}
      subtitle={`${translate(
        'reset_password.check_email.subtitle'
      )} ${email.charAt(0)}****${email.slice(email.indexOf('@'))}. ${translate(
        'reset_password.check_email.can_close_window'
      )}.`}
    />
  );
};
