export const ROUTES = {
    LANDING: '/',
    FORGOTTEN_PASSWORD: {
        SEND: '/reset-password',
        VERIFY: '/reset-password/verify/:code',
        RESET: '/reset-password/reset',
    },
    HOME: '/home',
    PROFILE: '/profile',
    CHECKOUT_VALIDATION: '/checkout/validation',
    EXPERIENCE: {
        EDITEXPERIENCE: '/experience/:id',
        EXPERIENCE_DETAIL: '/experience/:id/profile',
        RESOURCE: '/experience/:id/resource',
        SCHEDULING: '/experience/scheduling', // aca se le pasa :expId/:subExpId, no se porque nunca se le agrego
    },
    BOOKINGS: {
        GLOBAL: '/booking',
        ADD: '/booking/add',
        NEW: '/booking/new',
        ID: '/booking/:id',
        DATE: '/booking/:date/:expId/:subExpId',
    },
    RESOURCE: {
        RESOURCE: '/resource',
        ADD: '/resource/add',
    },
};
