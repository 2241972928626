import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useBookings } from 'core/contexts/booking';
import { ROUTES } from 'core/routing/routes';
import PaymentPlatform from 'core/libs/types/PaymentPlatform/PaymentPlatform';
import CircularIndeterminate from 'core/libs/core-ui/components/Progress';

const PaymentValidation = () => {
    const history = useHistory();
    const { addContractBookingPayment } = useBookings();
    const [status, setStatus] = useState(null);
    const [bookingId, setBookingId] = useState('');

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const sessionId = urlParams.get('session_id');
        const paymentId = urlParams.get('payment');
        setBookingId(urlParams.get('booking'));

        addContractBookingPayment({
            paymentId,
            paymentUID: paymentId,
            sessionId,
            status: 'approved',
            statusId: 3,
            paymentPlatformId: PaymentPlatform.IDS.stripe,
        }).then(([data]) => {
            setStatus(data.status);
        });
    }, []);
    useEffect(() => {
        if (status === 'open' || status === 'failure') {
            toast.error(`Hubo un error al intentar crear la reserva!`, {
                position: 'top-right',
                theme: 'colored',
            });
            history.push(ROUTES.BOOKINGS.NEW);
        }

        if (status === 'approved') {
            toast.success(`Reserva creada con éxito!`, {
                position: 'top-right',
                theme: 'colored',
            });
            history.push(`${ROUTES.BOOKINGS.GLOBAL}/${bookingId || ''}`);
        }
    }, [status]);
    // Add loader!
    return <CircularIndeterminate centered={true} />;
};

export default PaymentValidation;
