import clsx from 'clsx';

import AddIcon from 'assets/icons/home/add.svg';
import { Grid, Typography, Button, Tooltip } from '@material-ui/core';

import { ROUTES } from 'core/routing/routes';
import ExperiecerData from './components/ExperiencerData';
import ExperiencesList from './components/ExperiencesList';
import { useMediaQuery } from '@material-ui/core';
import { useStyles } from './styles';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'core/contexts/localization';
import { useState } from 'react';
import { Archive, Unarchive } from '@material-ui/icons';

const ExperiencerHomePage = () => {
    const classes = useStyles();
    const history = useHistory();
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    const { t: translate } = useTranslation();

    // Estado para controlar la vista de experiencias
    const [showActive, setShowActive] = useState(true);

    // Función para alternar entre vistas activas y archivadas
    const handleToggleExperiences = () => {
        setShowActive((prev) => !prev);
    };

    return (
        <>
            <Grid
                container
                className={clsx(classes.container, classes.gridContainer)}
            >
                <Grid item container align="center" xs={12} md={12}>
                    <ExperiecerData />
                </Grid>
            </Grid>
            <Grid container className={classes.container} spacing={3}>
                <Grid item xs={12}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                    >
                        <Grid item>
                            <Typography variant="h5">
                                {translate('home.experiencer.my_experiences')}
                            </Typography>
                            <Grid
                                item
                                style={{
                                    display: 'flex',
                                    flexDirection: 'flex-start',
                                }}
                            >
                                <Tooltip
                                    placement="right"
                                    title={
                                        showActive
                                            ? translate(
                                                  'home.triper.buttons.activeBtn'
                                              )
                                            : translate(
                                                  'home.triper.buttons.archiveBtn'
                                              )
                                    }
                                >
                                    <Button
                                        onClick={handleToggleExperiences}
                                        className={
                                            classes.toggleExperiencesButton
                                        }
                                    >
                                        {showActive ? (
                                            <Unarchive />
                                        ) : (
                                            <Archive />
                                        )}
                                    </Button>
                                </Tooltip>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <Grid container justifyContent="flex-end">
                                <Grid item>
                                    <Button
                                        className={classes.newBookingButton}
                                        onClick={() =>
                                            history.push(ROUTES.BOOKINGS.NEW)
                                        }
                                        startIcon={
                                            <img
                                                alt=""
                                                src={AddIcon}
                                                width={20}
                                                style={{ margin: 'auto' }}
                                            />
                                        }
                                    >
                                        {/* <Hidden smDown> */}
                                        {translate('calendar.add_booking')}
                                        {/* </Hidden> */}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        onClick={() =>
                                            history.push(
                                                '/experience/0/profile'
                                            )
                                        }
                                        className={classes.newExperienceButton}
                                        startIcon={
                                            <img
                                                alt=""
                                                src={AddIcon}
                                                width={20}
                                            />
                                        }
                                    >
                                        {translate(
                                            'home.experiencer.buttons.add_experience'
                                        )}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <ExperiencesList showActive={showActive} />
            </Grid>
        </>
    );
};

ExperiencerHomePage.displayName = 'ExperiencerHomePage';

export default ExperiencerHomePage;
