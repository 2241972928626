import React, { useEffect, useRef } from 'react';
import Grid from '@toast-ui/react-grid';
// import 'tui-date-picker/dist/tui-date-picker.css';
// import 'tui-time-picker/dist/tui-time-picker.css';
import 'tui-grid/dist/tui-grid.css';

const DataGrid = ({
    data,
    columns,
    rowHeight,
    bodyHeight,
    heightResizable,
    rowHeaders,
    setItemsGrid,
    itemsGrid,
    ...props
}) => {
    const gridRef = useRef(null);

    const convertDateString = (dateString) => {
        // Crear un objeto Date a partir de la cadena
        const date = new Date(dateString);

        // Obtener los componentes de la fecha
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Mes 0-11
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        // Formatear la fecha en el formato deseado
        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;
    };

    const handleAfterChange = (ev) => {
        const { changes } = ev;

        changes.forEach((change) => {
            const clone = itemsGrid;
            const changedItem = clone[changes[0].rowKey];
            const newValue = change.value;

            if (change.columnName === 'date') {
                const newDate = convertDateString(newValue);
                changedItem.date = newValue;
                changedItem.dateFormatted = newDate;
                changedItem.timezone =
                    Intl.DateTimeFormat().resolvedOptions().timeZone;
                setItemsGrid([...clone]);
            }
            if (change.columnName === 'price') {
                changedItem.price = parseInt(newValue);
                changedItem.totalPrice = parseInt(
                    newValue * changedItem.quantity
                );
                setItemsGrid([...clone]);
            }
            if (change.columnName === 'quantity') {
                changedItem.quantity = parseInt(newValue);
                changedItem.totalPrice = parseInt(changedItem.price * newValue);
                setItemsGrid([...clone]);
            }
            if (change.columnName === 'currency') {
                changedItem.currency = newValue;
                setItemsGrid([...clone]);
            }
            if (change.columnName === 'duration') {
                changedItem.duration = parseInt(newValue)
                    ? parseInt(newValue)
                    : 0;
                setItemsGrid([...clone]);
            }
        });
    };

    return (
        <>
            <Grid
                ref={gridRef}
                usageStatistics={false}
                data={data}
                columns={columns}
                rowHeight={rowHeight}
                bodyHeight={bodyHeight}
                heightResizable={heightResizable}
                rowHeaders={rowHeaders}
                onAfterChange={handleAfterChange}
                {...props}
            />
        </>
    );
};

export default DataGrid;
