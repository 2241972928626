import RatingIcon from 'assets/icons/experience/rating.svg';
import LocationIcon from 'assets/icons/experience/location.svg';
import IncludeIcon from 'assets/icons/experience/include.svg';
import RequireIcon from 'assets/icons/experience/require.svg';
import ModusIcon from 'assets/icons/modus.svg';
import EnvironmentIcon from 'assets/icons/experience/environment.svg';
import CancelIcon from 'assets/icons/experience/cancel.svg';
import TimeIcon from 'assets/icons/experience/time.svg';
import BookingIcon from 'assets/icons/percentage.svg';
import PriceIcon from 'assets/icons/price.svg';

export const highlights = (translate) => [
    {
        id: '0',
        icon: RatingIcon,
        color: 'rgb(219,161,62)',
        label: 'Rating',
    },
    {
        id: '1',
        icon: LocationIcon,
        color: 'rgb(0,68,142)',
        label: translate('experienceProfile.buttonsTitle.location'),
    },
    {
        id: '6',
        icon: TimeIcon,
        color: 'rgb(0,68,142)',
        label: translate('experienceProfile.buttonsTitle.timing'),
    },
    {
        id: '7',
        icon: BookingIcon,
        color: 'rgb(0,68,142)',
        label: translate('experienceProfile.buttonsTitle.booking'),
    },
    {
        id: '2',
        icon: ModusIcon,
        color: 'rgb(103,87,245)',
        label: translate('experienceProfile.buttonsTitle.moduses'),
    },
    {
        id: '3',
        icon: EnvironmentIcon,
        color: 'rgb(10,190,103)',
        label: translate('experienceProfile.buttonsTitle.social'),
    },
    {
        id: '4',
        icon: CancelIcon,
        color: 'rgb(252,88,78)',
        label: translate('experienceProfile.buttonsTitle.cancelation'),
    },
    {
        id: '5',
        icon: IncludeIcon,
        color: 'rgb(252,88,78)',
        label: translate('experienceProfile.buttonsTitle.includes'),
    },
    {
        id: '8',
        icon: RequireIcon,
        color: 'rgb(252,88,78)',
        label: translate('experienceProfile.buttonsTitle.requires'),
    },
];

export const modalsTemp = {
    envPolicies: [],
    cancelPolicies: [],
    include: [],
    modus: [],
    paymentMethods: [],
    requires: [],
};

export const viewModel = {
    cancelPolicies: {
        nonRefundable: true,
        additionals: [],
    },
    createdOn: '',
    description1: '',
    description2: '',
    description3: '',
    email: '',
    emailAlt: '',
    envPolicies: {
        trashRecycling: false,
        greenEnergy: false,
        foodWasteOpt: false,
        ngoCollaboration: false,
    },
    envPoliciesOther: null,
    // expDetail: [],
    experiencerId: 0,
    facebook: '',
    id: 0,
    images: [],
    include: {
        transportation: false,
        ticket: false,
        food: false,
        beverage: false,
        photography: false,
        accommodation: false,
    },
    includeOther: null,
    instagram: '',
    location: null,
    latitude: null,
    longitude: null,
    locationId: null,
    mobile: '',
    modus: [],
    name: '',
    nativeName: '',
    phone: '',
    pictureURL: null,
    status: 0,
    tags: '',
    triperId: 1,
    twitter: '',
    webSite: '',
    whatsapp: '',
    payNowValue: 0,
    paymentMethods: [],
    anticipation: 0,
    requireId: false,
    requires: [],
    currency: 'COP',
    price: null,
};

export const cleanViewModel = (input) => {
    Object.keys(input).map((key) => {
        // console.log(key, 'view key');
        if (key === 'id') {
        } else if (key === 'cancelPolicies') {
            input[key] = {
                nonRefundable: false,
                additionals: [],
            };
        } else if (key === 'envPolicies') {
            input[key] = {
                trashRecycling: false,
                greenEnergy: false,
                foodWasteOpt: false,
                ngoCollaboration: false,
            };
        } else if (key === 'include') {
            input[key] = {
                transportation: false,
                ticket: false,
                food: false,
                beverage: false,
                photography: false,
                accommodation: false,
            };
        } else if (key === 'modus') {
            input[key] = [];
        }
        // else if (key === 'expDetail') {
        //   input[key] = [];
        // }
        else if (key === 'paymentMethods') {
            input[key] = [];
        } else if (key === 'anticipation' || key === 'payNowValue') {
            input[key] = 0;
        } else {
            input[key] = '';
        }
    });
    console.log(input, 'clean view model');
    const output = { ...input };
    return output;
};
Object.keys(modalsTemp).map((key) => {
    modalsTemp[key] = [];
});

export let regexAlpha = new RegExp(
    '^[a-zA-ZáéíóúñÁÉÍÓÚÑ _.,!()+=`,&"@$#%*-]+$'
);

export let regexAlphaNumeric = new RegExp(
    '^[a-zA-ZáéíóúñÁÉÍÓÚÑ 0-9 _.,!()+=`,&"@$#%*-]+$'
);

export let regexNumeric = new RegExp('^[0-9 ,]');

/*
  {
    id: '2',
    icon: TimeIcon,
    color: 'rgb(255,106,61)',
    label: '1.5 hours experience',
  },
*/
/*
  {
    id: '6',
    icon: CancelIcon,
    color: 'rgb(252,88,78)',
    label: 'Not Includes',
  },*/
