import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { Grid, Typography, Button, Hidden } from '@material-ui/core/';

import ResourceTable from '../ResourceTable';
import ResourceAdd from '../ResourceAdd';
import ResourceTypeAdd from '../ResourceTypeAdd/index';

import AddIcon from 'assets/icons/home/add.svg';
// import SearchBox from 'core/libs/core-ui/components/SearchBox';
import AlertDialog from 'core/libs/core-ui/components/Dialog';
import ModusChip from 'core/libs/core-ui/components/Chip';

import { DateFormattedUtils } from 'core/libs/core-ui/utils/dateTimeFormatted';

import { useTranslation } from 'core/contexts/localization';

import { ResourceService } from 'core/api/services/resource';

import { useStyles } from './styles';
import CircularIndeterminate from 'core/libs/core-ui/components/Progress';

const ResourcePageComponent = () => {
    const classes = useStyles();
    const { t: translate } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalTypeOpen, setIsModalTypeOpen] = useState(false);
    const [resources, setResources] = useState([]);
    const [resourceSelected, setResourceSelected] = useState({});
    const [categorySeletected, setcategorySeletected] = useState();
    const [categoriesData, setCategoriesData] = useState();
    const [loading, setLoading] = useState(false);

    const closeTypeModal = () => {
        setIsModalTypeOpen(false);
    };

    const refreshResources = async () => {
        getResources();
    };

    const refreshCategories = async () => {
        getCategories();
    };

    const getNameCategoryFromId = (categoryId, categories) => {
        if (categoriesData) {
            const category = categories.find((c) => c.id === categoryId);
            return category ? category.name : 'Unknown Category';
        }
        return null;
    };

    const getNameCategory = (resource) => {
        return resource?.resourceCategories?.map((cat) => (
            <ModusChip label={cat?.name} />
        ));
    };

    const prettifyResources = (resources, categories) => {
        return resources?.map((resource) => {
            return {
                createdOnFormatted: DateFormattedUtils(resource.createdOn),
                categoryName: getNameCategory(resource),
                ...resource,
            };
        });
    };

    const openModal = (event, id) => {
        if (id) {
            ResourceService.getResource(id)
                .then((result) => {
                    if (result) {
                        setResourceSelected(result);
                        setIsModalOpen(true);
                    } else {
                        console.error('La respuesta no fue exitosa:', result);
                        toast.error(translate('resource.resource_error'), {
                            position: 'top-right',
                            theme: 'colored',
                        });
                    }
                })
                .catch((error) => {
                    console.error('Error al enviar el formulario:', error);
                    toast.error(translate('resource.resource_error'), {
                        position: 'top-right',
                        theme: 'colored',
                    });
                });
        } else {
            setResourceSelected({});
            setIsModalOpen(true);
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };
    const openTypeModal = (selectedOption) => {
        setIsModalOpen(false);
        setIsModalTypeOpen(true);
        setcategorySeletected(selectedOption);
    };
    const getResources = async () => {
        try {
            const resourcesData = await ResourceService.getResources();
            if (resourcesData) {
                const prettyData = prettifyResources(
                    resourcesData,
                    categoriesData
                );
                setResources(prettyData);
            } else {
                throw new Error();
            }
        } catch (err) {
            console.error(err);
            return false;
        } finally {
        }
    };
    const getCategories = async () => {
        try {
            const categoriesData = await ResourceService.getCategories();
            if (!categoriesData) {
                throw new Error();
            }
            categoriesData.push({
                id: 0,
                name: translate('resource.add_category'),
            });
            setCategoriesData(categoriesData);
        } catch (err) {
            console.error(err);
            return false;
        } finally {
        }
    };

    useEffect(() => {
        const loadCategories = async () => {
            setLoading(true);
            try {
                const categoriesData = await ResourceService.getCategories();
                if (categoriesData) {
                    categoriesData.push({
                        id: 0,
                        name: translate('resource.add_category'),
                    });
                    setCategoriesData(categoriesData);
                }
            } catch (error) {
                console.error('Error loading categories:', error);
            } finally {
                setLoading(false);
            }
        };

        loadCategories();
    }, []);

    useEffect(() => {
        if (categoriesData) {
            const loadResources = async () => {
                setLoading(true);
                try {
                    const resourcesData = await ResourceService.getResources();
                    if (resourcesData) {
                        const prettyData = prettifyResources(
                            resourcesData,
                            categoriesData
                        );
                        setResources(prettyData);
                    }
                } catch (error) {
                    console.error('Error loading resources:', error);
                } finally {
                    setLoading(false);
                }
            };

            loadResources();
        }
    }, [categoriesData]);

    return (
        <Grid container spacing={2} className={classes.container}>
            <Grid item xs={12} container justifyContent="space-between">
                <Grid item>
                    <Typography variant="h1" style={{ display: 'inline' }}>
                        {translate('resource.title')}
                    </Typography>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        className="triper-button bg-green-300 right"
                        onClick={openModal}
                        startIcon={
                            <img
                                alt=""
                                src={AddIcon}
                                width={20}
                                className="mr-2"
                            />
                        }
                    >
                        <Hidden smDown>
                            {translate('resource.add_resource')}
                        </Hidden>
                    </Button>
                </Grid>
            </Grid>
            {loading ? (
                <CircularIndeterminate />
            ) : (
                <Grid item xs={12} className={classes.containerTable}>
                    <ResourceTable
                        openModal={openModal}
                        resources={resources}
                    />
                </Grid>
            )}
            <AlertDialog
                open={isModalOpen}
                handleClose={closeModal}
                dialogTitle=" "
                hideActions={true}
                className={classes.modalContent}
                labelBack="Salir"
                labelNext="Crear"
                dialogContent={
                    <>
                        <ResourceAdd
                            openModalType={(e) => openTypeModal(e)}
                            onClose={closeModal}
                            resourceSelected={resourceSelected}
                            categoriesData={categoriesData}
                            onPostSuccess={refreshResources}
                        />
                    </>
                }
            />
            <AlertDialog
                open={isModalTypeOpen}
                handleClose={closeTypeModal}
                dialogTitle=" "
                hideActions={true}
                className={classes.modalContent}
                labelBack="Salir"
                labelNext="Crear"
                dialogContent={
                    <>
                        <ResourceTypeAdd
                            onClose={closeTypeModal}
                            categorySeletected={categorySeletected}
                            onPostSuccess={refreshCategories}
                        />
                    </>
                }
            />
        </Grid>
    );
};

export default ResourcePageComponent;
