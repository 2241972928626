import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { ROUTES } from './routes';

import Landing from 'modules/Landing';
import ForgottenPassword from 'modules/ForgottenPassword';
import ResetPassword from 'modules/ForgottenPassword/Reset';
import VerifyCode from 'modules/ForgottenPassword/Verify';
import ResourceAdd from 'modules/Resource/componenets/ResourceAdd';
import ResourcePage from 'modules/Resource';
import ResourceExperiencePage from 'modules/Experiences/components/Resource/ResourceExperience';

import HomePage from 'modules/Home';

import ProfilePage from 'modules/Profile';

import ExperiencerCalendarPage from 'modules/Experiences/components/Calendar';

// import ExperiencerBookings from 'modules/Bookings';
import BookingDetailsPage from 'modules/Bookings/components/Calendar/BookingDetailsPage';
import BookingPage from 'modules/Bookings/Booking';
import GlobalBookings from 'modules/Bookings';
import GlobalAddBooking from 'modules/Bookings/components/GlobalAddBooking';

import { ExperienceInfoPage } from 'modules/Experiences/ExperienceInfo';
import { EditExperience } from 'modules/Experiences/EditExperience';

import RedirectRoute from './redirectRoute';
import BookingNewPage from 'modules/Bookings/components/BookingNew';
import PaymentValidation from 'modules/Bookings/components/BookingNew/components/PaymentValidation';

const Router = () => (
    <BrowserRouter>
        <Switch>
            <Route path={ROUTES.LANDING} exact>
                <Landing />
            </Route>
            <Route
                path={ROUTES.FORGOTTEN_PASSWORD.VERIFY}
                component={VerifyCode}
                exact
            />
            <Route
                path={ROUTES.FORGOTTEN_PASSWORD.RESET}
                component={ResetPassword}
                exact
            />
            <Route
                path={ROUTES.FORGOTTEN_PASSWORD.SEND}
                component={ForgottenPassword}
            />
            <RedirectRoute path={ROUTES.HOME} component={HomePage} />
            <RedirectRoute path={ROUTES.PROFILE} component={ProfilePage} />
            <RedirectRoute
                path={ROUTES.EXPERIENCE.SCHEDULING}
                component={ExperiencerCalendarPage}
            />
            <RedirectRoute
                path={ROUTES.EXPERIENCE.EXPERIENCE_DETAIL}
                component={ExperienceInfoPage}
            />
            <RedirectRoute
                path={ROUTES.EXPERIENCE.RESOURCE}
                component={ResourceExperiencePage}
            />
            <RedirectRoute
                path={ROUTES.EXPERIENCE.EDITEXPERIENCE}
                component={EditExperience}
            />
            <RedirectRoute
                path={ROUTES.CHECKOUT_VALIDATION}
                component={PaymentValidation}
                exact
            />

            <RedirectRoute
                path={ROUTES.BOOKINGS.ADD}
                component={GlobalAddBooking}
                exact
            />
            <RedirectRoute
                path={ROUTES.BOOKINGS.NEW}
                component={BookingNewPage}
                exact
            />
            <RedirectRoute
                path={ROUTES.BOOKINGS.ID}
                component={BookingPage}
                exact
            />
            <RedirectRoute
                path={ROUTES.BOOKINGS.DATE}
                component={BookingDetailsPage}
                exact
            />
            <RedirectRoute
                path={ROUTES.BOOKINGS.GLOBAL}
                component={GlobalBookings}
            />
            <RedirectRoute path={ROUTES.RESOURCE.RESOURCE} exact>
                <ResourcePage />
            </RedirectRoute>
            <RedirectRoute path={ROUTES.RESOURCE.ADD} exact>
                <ResourceAdd />
            </RedirectRoute>
        </Switch>
    </BrowserRouter>
);

Router.displayName = 'Router';

export default Router;
