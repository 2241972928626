import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Layout from 'components/Layout';
import { useBookings } from 'core/contexts/booking';
import { useTranslation } from 'core/contexts/localization';
import { CircularProgress, useMediaQuery } from '@material-ui/core';
import { toast } from 'react-toastify';
import { useStyles } from './styles';
import BookingPageComponent from 'core/libs/core-ui/components/BookingPage';
import BookingStatus from 'core/libs/status/BookingStatus/BookingStatus';
import { ScheduleService } from 'core/api/services/schedule';

const BookingPage = () => {
    const classes = useStyles();
    const { t: translate } = useTranslation();
    const { getBooking, updateBooking, booking, loading, setBooking } =
        useBookings();
    const [originalBooking, setOriginalBooking] = useState({});
    const [canEdit, setCanEdit] = useState(false);
    const [isCancel, setIsCancel] = useState(false);
    const [isPending, setIsPending] = useState(
        BookingStatus.IDS.pending === originalBooking.status
    );
    const [hasChanged, setHasChanged] = useState(false);

    const locationParam = document.location.href.split('/');
    const bookingIndex = locationParam[locationParam.length - 1];

    useEffect(() => {
        setBooking({});
        if (bookingIndex == 0) {
            setCanEdit(true);
        } else {
            getBooking(bookingIndex);
        }
    }, []);

    useEffect(() => {
        // We do this to be able to cancel and revert unsaved changes
        if (!originalBooking.id || originalBooking.id !== booking.id) {
            const auxBooking = {
                ...booking,
            };
            setOriginalBooking(auxBooking);
            setIsPending(BookingStatus.IDS.pending === auxBooking.status);
        }
        if (parseInt(booking.status) === BookingStatus.IDS.cancelled) {
            setIsCancel(true);
        }
    }, [booking]);

    const onSave = async (values) => {
        try {
            // values = { experiencer: userInfo };
            const valuesClon = { ...values };
            const res = await updateBooking(valuesClon);
            if (res) {
                toast.success('Booking updated!', {
                    position: 'top-right',
                    theme: 'colored',
                });
            }
            getBooking(bookingIndex);
            setOriginalBooking(booking);
            setIsPending(BookingStatus.IDS.pending === booking.status);
        } catch (err) {
            toast.error(err, {
                position: 'top-right',
                theme: 'colored',
            });
        }
    };

    const onCancel = () => {
        setBooking(originalBooking);
    };

    return (
        <Layout>
            {/* <Title value={'Booking'} /> */}
            <div>
                {loading ? (
                    <CircularProgress />
                ) : (
                    // STARTS BOOKING INFO
                    <div className={classes.content}>
                        <BookingPageComponent
                            booking={booking}
                            setBooking={setBooking}
                            canEdit={true}
                            hasChanged={hasChanged}
                            setHasChanged={setHasChanged}
                            onSave={onSave}
                            onCancel={onCancel}
                            accionButtons={{
                                setDate: {
                                    show: !isCancel,
                                },
                                setNoShow: {
                                    show: !isCancel,
                                },
                                setCancel: {
                                    show: !isCancel,
                                },
                                setConfirm: {
                                    show: isPending,
                                },
                            }}
                            showTriper={true}
                            getAvailability={ScheduleService.getAvailability}
                            translate={translate}
                        />
                    </div>
                    // ENDS BOOKING INFO
                )}
            </div>
        </Layout>
    );
};

BookingPage.displayName = 'BookingPage';

export default BookingPage;
