import { useState, useMemo, useEffect } from 'react';
import { Grid, TextField, Checkbox, InputLabel } from '@material-ui/core';
import { useTranslation } from 'core/contexts/localization';
import { green } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import {
    payment_methods,
    PAYMENT_METHODS,
    getTranslatedName as getPaymentMethodsTranslatedName,
} from 'core/libs/import/PaymentMethods';
import { useStyles } from './styles';
import SelectComponent from 'core/libs/core-ui/components/Input/Select';
import PayNow from 'core/libs/types/PayNow/PayNow';

const BookingPercentage = ({ experienceInfoTemp }) => {
    const classes = useStyles();

    const { t: translate, i18n } = useTranslation();
    const i18nLang = i18n.language;

    const GreenCheckbox = withStyles({
        root: {
            color: green[400],
            '&$checked': {
                color: green[600],
            },
        },
    })((props) => <Checkbox color="default" {...props} />);

    const isPaymentMethodChecked = (id) => {
        return experienceInfoTemp.paymentMethods.some(
            (method) => method.id === id
        );
    };

    const payNowOptions = useMemo(
        () =>
            PayNow?.getIds().map((id) => {
                const translated = PayNow.getTranslatedName(
                    PayNow.types,
                    id,
                    i18n.language
                );
                return { name: translated?.[0] ?? '', id };
            }),
        [PayNow, i18n.language]
    );

    const initialOptions = [
        {
            id: PAYMENT_METHODS.CASH.id,
            label: getPaymentMethodsTranslatedName(
                payment_methods,
                PAYMENT_METHODS.CASH.id,
                i18nLang
            ),
            checked: isPaymentMethodChecked(PAYMENT_METHODS.CASH.id),
        },
        {
            id: PAYMENT_METHODS.CREDIT_CARD.id,
            label: getPaymentMethodsTranslatedName(
                payment_methods,
                PAYMENT_METHODS.CREDIT_CARD.id,
                i18nLang
            ),
            checked: isPaymentMethodChecked(PAYMENT_METHODS.CREDIT_CARD.id),
        },
        {
            id: PAYMENT_METHODS.TRANSFER.id,
            label: getPaymentMethodsTranslatedName(
                payment_methods,
                PAYMENT_METHODS.TRANSFER.id,
                i18nLang
            ),
            checked: isPaymentMethodChecked(PAYMENT_METHODS.TRANSFER.id),
        },
        {
            id: PAYMENT_METHODS.MERCADO_PAGO.id,
            label: getPaymentMethodsTranslatedName(
                payment_methods,
                PAYMENT_METHODS.MERCADO_PAGO.id,
                i18nLang
            ),
            checked: isPaymentMethodChecked(PAYMENT_METHODS.MERCADO_PAGO.id),
        },
        {
            id: PAYMENT_METHODS.PAYPAL.id,
            label: getPaymentMethodsTranslatedName(
                payment_methods,
                PAYMENT_METHODS.PAYPAL.id,
                i18nLang
            ),
            checked: isPaymentMethodChecked(PAYMENT_METHODS.PAYPAL.id),
        },
    ];

    const [options, setOptions] = useState(initialOptions);

    const [percentage, setPercentage] = useState(
        experienceInfoTemp.payNowValue ?? 0
    );
    const [payNowType, setPayNowType] = useState(
        experienceInfoTemp.payNowType ?? 0
    );

    useEffect(() => {
        if (PayNow.isTypePercentage(payNowType) && percentage > 100) {
            setPercentage(100);
        }
    }, [payNowType, percentage]);

    return (
        <div>
            <span className={classes.subtitle}>
                {translate('experienceProfile.saveModal.booking.subtitle1')}
            </span>
            <div className={classes.box}>
                <Grid
                    container
                    direction={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    spacing={1}
                >
                    <Grid item style={{ width: '100%', margin: '2px .25rem' }}>
                        <InputLabel>
                            {translate(
                                'experienceProfile.saveModal.payNow.payNowTypeLabel'
                            )}
                        </InputLabel>
                        <SelectComponent
                            id="booking-payNowType"
                            values={payNowOptions}
                            value={payNowType}
                            style={{ color: 'black' }}
                            onChange={(value) => {
                                experienceInfoTemp.payNowType = value.id;
                                setPayNowType(value.id);
                            }}
                        />
                    </Grid>
                    <Grid item style={{ width: '100%', margin: '2px .25rem' }}>
                        <InputLabel>
                            {translate(
                                'experienceProfile.saveModal.payNow.payNowValueLabel'
                            )}
                        </InputLabel>
                        <TextField
                            id="booking-percentage"
                            value={percentage}
                            onChange={(e) => {
                                let value = 0;
                                const targetValue = parseFloat(e.target.value);
                                if (
                                    targetValue > 100 &&
                                    PayNow.isTypePercentage(payNowType)
                                ) {
                                    value = 100;
                                } else if (targetValue < 0) {
                                    value = 0;
                                } else {
                                    value = targetValue;
                                }
                                // Limita a 4 decimales.
                                value = Math.round(value * 10000) / 10000;
                                experienceInfoTemp.payNowValue = value;
                                setPercentage(value);
                            }}
                            variant="outlined"
                            type="number"
                            inputProps={{
                                min: 0,
                                max: 100,
                                step: 1,
                                'aria-label': 'booking-percentage',
                            }}
                        />
                    </Grid>
                </Grid>
            </div>
            <span className={classes.subtitle}>
                {translate('experienceProfile.saveModal.booking.subtitle2')}
            </span>
            <div>
                {options.map((entry, index) => {
                    return (
                        <div>
                            <GreenCheckbox
                                key={index}
                                checked={entry.checked}
                                color="#2F80ED"
                                inputProps={{
                                    'aria-label': 'secondary checkbox',
                                }}
                                onClick={(e) => {
                                    const auxOptions = [...options];

                                    // if it was checked, uncheck it => remove it
                                    if (auxOptions[index].checked) {
                                        auxOptions[index].checked = false;
                                        experienceInfoTemp.paymentMethods =
                                            experienceInfoTemp.paymentMethods.filter(
                                                (method) =>
                                                    method.id !== entry.id
                                            );
                                    } else {
                                        auxOptions[index].checked = true;
                                        experienceInfoTemp.paymentMethods.push({
                                            id: entry.id,
                                            label: entry.label,
                                        });
                                    }
                                    setOptions(auxOptions);
                                }}
                            />
                            <span>{entry.label}</span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default BookingPercentage;
