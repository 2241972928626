import { TextField } from '@material-ui/core';
import { useTranslation } from 'core/contexts/localization';
import { useStyles } from './styles';
import GenericBox from '../GenericBox';

export const EmailBox = ({ value, setValue, errorComponent }) => {
  const { t: translate } = useTranslation();

  const classes = useStyles();

  return (
    <GenericBox
      title={translate('reset_password.forgotten_title')}
      subtitle={translate('reset_password.forgotten_subtitle') + '.'}>
      <style>
        {`
            .MuiInputBase-input {
              font-size: 1rem;
            }
          `}
      </style>
      <TextField
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        className={classes.inputBox}
        id="email"
        placeholder="Email"
        type="email"
        variant="outlined"
        margin="normal"
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
      />
      {errorComponent}
    </GenericBox>
  );
};
